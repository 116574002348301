import {mapGetters} from 'vuex';

const loginMsg = {
	title: 'Ingelogd',
	message: 'Welkom terug bij Ikcro!',
	type: 'success',
	position: 'bottom-right'
}

export default {
	data () {
		return {
			user: {
				email: '',
				password: ''
			},
			errorMessages: []
		}
	},
	watch: {
		isLoggedIn (loggedIn) {
			if(loggedIn) {
				this.$router.push('/')
				this.$notify(loginMsg);
			}
		}
	},
	computed: {
		...mapGetters({isLoggedIn: 'user/isLoggedIn'})
	},
	beforeCreate () {
		if(this.$store.getters['user/isLoggedIn']) {
			this.$router.push('/')
			this.$notify(loginMsg);
		}
	},
	methods: {
		login () {
			this.$store.dispatch('user/signIn', this.user).then(() => {
				this.errorMessages = []
			}).catch(() => {
				this.errorMessages = ['Email en wachtwoord combinatie onjuist']
			})
		}
	}
}